import React from 'react'
import {
  Text,
  Heading,
  Box,
  Container,
  Button,
  Link,
  Flex,
  Divider,
} from '@chakra-ui/react'
import { Styleguide } from '../components/index'

const StyleguidePage = () => {
  return (
    <Box mheight="100vh" py={20}>
      <Styleguide />
    </Box>
  )
}

export default StyleguidePage
